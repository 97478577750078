@import '@mixins'

.p-topbar
  position relative
  padding 0 5px
  width 100%
  height 60px
  background-color var(--pcolor-topbar)
  color var(--pcolor-gray-darkest)
  font-size 12px
  box-sizing border-box
  overflow hidden
  z-index var(--player-lowest)
  backdrop-filter blur(16px)
  -webkit-backdrop-filter blur(16px)
  box-shadow 0 .5px 0 0 var(--pcolor-topbar-shadow) inset
  select,
  .p-button
    box-shadow none
    outline 0 solid var(--pcolor-gray-lighter)
    &:focus
      box-shadow none
      outline 2px solid var(--pcolor-gray-lighter)
  &.p-topbar-menu-open
    .p-topbar-actions
      right -200px
    .p-topbar-homepage-link
      left -200px
    .p-topbar-menu-wrapper
      top 0
      opacity 1

.p-topbar-content
  position relative
  height 100%

.p-topbar-menu-wrapper,
.p-topbar-homepage-link,
.p-topbar-actions
  transition all 200ms ease-out

.p-topbar-actions
  position relative
  right 0
  display flex
  margin 0
  padding 0
  list-style-type none

.p-topbar-menu-wrapper
  position absolute
  display flex
  align-items center
  justify-content space-between
  top -60px
  width 100%
  z-index var(--player-lowest)
  opacity .25
  .p-menu
    display flex
    align-items center
    margin 0
    padding 0
    height 60px
    list-style-type none
    a
      color var(--pcolor-gray-darkest)
      text-decoration none

.p-topbar-main
  display flex
  align-items center
  justify-content space-between
  height 60px

.p-topbar-homepage-link
  position relative
  left 0
  padding 0 4px 0 24px
  overflow hidden
  .p-icon
    position absolute
    top 50%
    left 0
    width 20px
    height 20px
    transform translateY(-50%)

+breakpoint('xs')
  .p-topbar
    padding 0 20px
    max-width 335px
    border-radius 30px
  
  .p-topbar-actions
    gap 5px

/* This file is written in plain CSS because is also used in the Blog build */

:root {
  /* COLORS */
  --pcolor-gray-lightest          : #EEF0F6;
  --pcolor-gray-lighter           : #DADEE7;
  --pcolor-gray-light             : #CDD0D6;
  --pcolor-gray                   : #B8BEC6;
  --pcolor-gray-dark              : #6D7078;
  --pcolor-gray-darker            : #29303D;
  --pcolor-gray-darkest           : #161A21;
  --pcolor-lightest               : #FFFFFF;
  --pcolor-darkest                : #000000;
  --pcolor-red-dark               : #B31248;
  --pcolor-blue-dark              : #006BD6;
  --pcolor-green-dark             : #10A68D;
  --pcolor-yellow-dark            : #805500;
  --pcolor-topbar                 : rgba(225, 230, 240, .7);
  --pcolor-primary                : var(--pcolor-gray-darkest);
  --pcolor-text                   : var(--pcolor-gray-dark);
  --pcolor-card-text              : #686B73;
  --pcolor-outline-button-text    : var(--pcolor-gray-dark);
  --p-color-projects-bagdge-bg    : #E1197D;
  --p-color-projects-bagdge-text  : var(--pcolor-lightest);
  --pcolor-loader-button-bg       : var(--pcolor-gray-dark);
  --pcolor-timeline               : var(--pcolor-gray-light);
  --pcolor-topbar-shadow          : transparent;

  /* FONT-SIZE */
  --pfont-size-xxxs       : 0.625rem; /* 10px */
  --pfont-size-xxs        : 0.750rem; /* 12px */
  --pfont-size-xs         : 0.875rem; /* 14px */
  --pfont-size-sm         : 1.000rem; /* 16px */
  --pfont-size-md         : 1.125rem; /* 18px */
  --pfont-size-lg         : 1.250rem; /* 20px */
  --pfont-size-xl         : 1.500rem; /* 24px */
  --pfont-size-xxl        : 2.250rem; /* 36px */
  --pfont-size-xxxl       : 3.000rem; /* 48px */

  /* FONT-WEIGHT */
  --pfont-weight-text     : 400;

  /* FONT-FAMILY */
  --pfont-family-primary  : 'Inter', Helvetica, Arial, sans-serif;

  /* LAYERS */
  --player-base           : 01;
  --player-lowest         : 10;
  --player-low            : 20;
  --player-middle         : 30;
  --player-high           : 40;
  --player-highest        : 50;
}

[data-ptheme="dark"] {
  /* COLORS */
  color-scheme: dark;
  --pcolor-gray-lightest          : #161A21;
  --pcolor-gray-lighter           : #29303D;
  --pcolor-gray-light             : #777B84;
  --pcolor-gray                   : #B8BEC6;
  --pcolor-gray-dark              : #CDD0D6;
  --pcolor-gray-darker            : #DDE0E6;
  --pcolor-gray-darkest           : #EBEDf5;
  --pcolor-red                    : #D998A4;
  --pcolor-red-dark               : #D998A4;
  --pcolor-blue-dark              : #66A6FF;
  --pcolor-green-dark             : #52CCBC;
  --pcolor-yellow-dark            : #FFDD99;
  --pcolor-lightest               : #000000;
  --pcolor-darkest                : #FFFFFF;
  --pcolor-topbar                 : rgba(50, 60, 70, .5);
  --pcolor-text                   : var(--pcolor-gray-light);
  --pcolor-card-text              : #878A92;
  --pcolor-outline-button-text    : var(--pcolor-gray);
  --p-color-projects-bagdge-text  : var(--pcolor-darkest);
  --pcolor-loader-button-bg       : var(--pcolor-gray-light);
  --pcolor-timeline               : #43474E;
  --pcolor-topbar-shadow          : #464646;

  /* FONT-WEIGHT */
  --pfont-weight-text     : 500;
}
